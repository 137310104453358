import React from "react"

import Layout from "../../layouts/Fi"

import SEO from "../../components/SEO"
import Card from "../../components/Card"

import Hat from "../../icons/hat.svg"
import Tools from "../../icons/tools.svg"
import Brackets from "../../icons/brackets.svg"

const PalvelutPage = () => (
  <Layout title="Mitä teemme">
    <SEO keywords={[`mitä`, `teemme`]} title="Palvelut" />

    <section className="flex justify-center text-center mb-12">
      <div className="w-full sm:w-3/4 md:w-2/3">
        <p className="mb-8">
          Palveluihimme kuuluu ohjelmistokehityksen ja ohjelmistointegraatioiden
          lisäksi IT-palveluiden suunnittelu, rakentaminen ja ylläpito.
          Toimintamme perusta on palveluiden ja tuotteiden toimivuus,
          realistinen hinta-laatusuhde ja riippumattomuuden turvaaminen.
          Tavoitteenamme on yhdessä asiakkaan kanssa kehittää ja ylläpitää
          asiakkaan tarpeisiin nopeasti mukautuva toimintaympäristö.
        </p>
      </div>
    </section>

    <section className="flex flex-wrap justify-center text-center mb-12">
      <div className="w-full md:w-1/2 pb-8">
        <Card className="mx-auto md:mx-4 h-full">
          <Hat className="mx-auto mb-8 h-24 text-orange" />
          <h3 className="mb-4">Integrointi</h3>
          <p>
            Ohjelmistointegroinnin lisäksi teemme sekä
            toimintaympäristöintegrointia että pienten ja keskisuurten
            järjestelmien integrointeja. Pitkä kokemuksemme mahdollistaa
            vaativienkin integrointien nopean suorittamisen.
          </p>
        </Card>
      </div>

      <div className="w-full md:w-1/2 pb-8">
        <Card className="mx-auto md:mx-4 h-full">
          <Tools className="mx-auto mb-8 h-24 text-orange" />
          <h3 className="mb-4">IT-tuki</h3>
          <p>
            Konsultoimme asiakkaitamme erilaisten järjestelmien, mm. koneet,
            palvelimet, testerit, diagnostiikkalaitteet ja sovellusten
            hankinnassa. Suoritamme niiden tilauksen, asennuksen, ylläpidon,
            valvonnan ja hallinnoinnin. Annamme käyttöön tarvittavan koulutuksen
            ja käyttötuen. Palveluihimme kuuluu myös tiedonvarmistaminen ja
            palautus.
          </p>
        </Card>
      </div>

      <div className="w-full md:w-1/2 pb-8">
        <Card className="mx-auto md:mx-4 h-full">
          <Brackets className="mx-auto mb-8 h-24 text-orange" />
          <h3 className="mb-4">Verkkosivut</h3>
          <p>
            Luomme verkkosivut, -kaupan tai -sovelluksen juuri tarpeisiisi
            räätälöityinä vahvalla ammattitaidolla ja nykyaikaisilla
            teknologioilla. Hoidamme tarvittaessa myös hostauksen ja ylläpidon.
          </p>
        </Card>
      </div>
    </section>
  </Layout>
)

export default PalvelutPage
