import PropTypes from "prop-types"
import React from "react"

const Card = ({ children, className, ...props }) => (
  <div
    className={`bg-white shadow-orange rounded-lg px-8 py-12 ` + className}
    {...props}
  >
    {children}
  </div>
)

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Card
